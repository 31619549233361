<template>
    <a-input-search
      class="search_input"
      placeholder="Rechercher"
      @change="sendSearchEvent"
      @search="$emit('search')"
      @click="$emit('click')"
      @keypress="$emit('keypress')"
      enterButton
    />
</template>
<script>
export default {
  name : "SearchInput",
  methods: {
    sendSearchEvent($event) {
      this.$emit("change", $event.target.value);
    }
  }
};
</script>

<style scoped>
.search_input {
  width: 100% ;
}
</style>