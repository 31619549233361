<template>
  <div>
    <h1 style="margin-left:0%;margin-bottom:4%">Utilisateurs</h1>
    <UtilisateursList></UtilisateursList>
  </div>
</template>

<script>
import UtilisateursList from "./UtilisateursList";

export default {
  name: "UtilisateursIndex",
  components: { UtilisateursList }
};
</script>

<style scoped>
</style>