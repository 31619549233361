<template>
  <div>
    <a-row type="flex" justify="center" class="table_head">
      <a-col :span="7">
        <router-link to="/admin/utilisateurs/create">
          <a-button class="editable-add-btn" type="primary">Nouvel utilisateur</a-button>
        </router-link>
      </a-col>
      <a-col :span="6">
        <search-component v-on:change="searchUtilisateur($event)"></search-component>
      </a-col>
    </a-row>
    <a-row type="flex" justify="center">
      <a-col :span="14">
        <a-spin :spinning="processing">
          <a-table
            bordered
            :dataSource="dataSource"
            :columns="columns"
            :rowKey="(record) => {return record.id }"
          >
            <template slot="row_index" slot-scope="text, record, index">
              <p>{{index + 1 }}</p>
            </template>

            <template slot="operation" slot-scope="text, record">
              <a-popconfirm
                v-if="dataSource.length"
                title="Etes-vous sûr de vouloir supprimer cette ligne ?"
                @confirm="() => onDelete(record)"
              >
                <a-tooltip>
                  <a-icon
                    type="delete"
                    class="action_icons"
                    theme="twoTone"
                    twoToneColor="#eb2f96"
                  />
                  <template slot="title">Suppression</template>
                </a-tooltip>
              </a-popconfirm>
              <a-tooltip>
                <a-icon
                  type="edit"
                  v-on:click="handleEdit(record.userId)"
                  class="action_icons"
                  theme="twoTone"
                  twoToneColor="#52c41a"
                />
                <template slot="title">Edition</template>
              </a-tooltip>
            </template>
            <template slot="etat" slot-scope="text, record">
              <a-icon v-if="text==1" type="check-circle" theme="twoTone" twoToneColor="#52c41a"/>
              <a-icon v-else type="pause-circle" theme="twoTone" twoToneColor="#eb2f96"/>
            </template>
          </a-table>
        </a-spin>
      </a-col>
    </a-row>
  </div>
</template>
<script>
  // import EditableCell from '@/components/common/EditableCell';
  import {mapActions, mapGetters, mapState} from "vuex";
  import SearchComponent from "@/components/common/Search";

  export default {
    name: "UtilisateursList",
    created() {
      this.getAdditioanlData()
              .finally(() => this.processing = false);
    },
    components: {
      // EditableCell,
      SearchComponent
    },
    data() {
      return {
        processing: true,
        columns: [
          {
            title: "N°",
            scopedSlots: {customRender: "row_index"}
          },
          {
            title: "Nom utilisateur",
            dataIndex: "userName"
        },
        {
          title: "Nom et Prénom",
          dataIndex: "fullName"
        },
        // {
        //   title: "Etat",
        //   dataIndex: "enabled",
        //   scopedSlots: { customRender: "etat" }
        // },
        {
          title: "Action",
          scopedSlots: { customRender: "operation" }
        }
      ]
    };
  },
  computed: {
    ...mapState({ dataSource: state => state.utilisateurs.utilisateurs }),
    ...mapGetters({ count: "totalUtilisateurs" })
  },
  methods: {
    searchUtilisateur(e) {
      this.search(e);
    },
    onDelete(record) {
      this.delete(record.userId);
    },
    handleEdit(id) {
      this.$router.push(`/admin/utilisateurs/edit/${id}`);
    },
    ...mapActions({
      getAdditioanlData: "fetchUtilisateurs",
      update: "updateUtilisateur",
      delete: "destroyUtilisateur",
      add: "storeUtilisateur",
      search: "filteredListOfUtilisateurs"
    })
  }
};
</script>
<style>
.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 18px;
   
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}

.decission_btn {
  text-decoration: underline;
}
.action_icons {
  margin: 4px;
  font-size: large;
}
.search_input {
  width: max-content !important;
}
.table_head {
  margin-bottom: 20px;
}
</style>